// import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import styles from './FadeIn.module.scss';

const FadeIn = ({ children }) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    const classes = classNames(styles['FadeIn'], {
        [styles['FadeIn--Visible']]: inView,
    });

    return (
        <div ref={ref} className={classes}>
            {children}
        </div>
    );
};

FadeIn.propTypes = {};

export default FadeIn;
