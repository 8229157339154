import React from 'react';

import dynamic from 'next/dynamic';
import { snakeToPascal } from '../../utils/caseconverters';
import FadeIn from '../FadeIn/FadeIn';

const ArticleCtaLarge = dynamic(() => import('../ArticleCtaLarge'));
const ArticleImageText = dynamic(() => import('../ArticleImageText'));
const ArticleNumbers = dynamic(() => import('../ArticleNumbers'));
const ArticlePreamble = dynamic(() => import('../ArticlePreamble'));
const ArticleQuote = dynamic(() => import('../ArticleQuote'));
const ArticleRichText = dynamic(() => import('../ArticleRichText'));
const ChartBlock = dynamic(() => import('../ChartBlock'));
const Contact = dynamic(() => import('../Contact'));
const CtaButton = dynamic(() => import('../CtaButton'));
const CtaLarge = dynamic(() => import('../CtaLarge'));
const CtaSlim = dynamic(() => import('../CtaSlim'));
const CtaWithImage = dynamic(() => import('../CtaWithImage'));
const FaqList = dynamic(() => import('../FaqList'));
const FiftyFiftyCtaList = dynamic(() => import('../FiftyFiftyCtaList'));
const HeroArticlePage = dynamic(() => import('../HeroArticlePage'));
const HeroArticlePageVideo = dynamic(() => import('../HeroArticlePageVideo'));
const HeroCustomerServicePage = dynamic(
    () => import('../HeroCustomerServicePage')
);
const HeroLandingPage = dynamic(() => import('../HeroLandingPage'));
const HeroLandingPageParallax = dynamic(
    () => import('../HeroLandingPageParallax')
);
const HeroPodcastPage = dynamic(() => import('../HeroPodcastPage'));
const HeroStartPage = dynamic(() => import('../HeroStartPage'));
const ImageLinkList = dynamic(() => import('../ImageLinkList'));
const InfoText = dynamic(() => import('../InfoText'));
const InlineImage = dynamic(() => import('../InlineImage'));
const LinkListLarge = dynamic(() => import('../LinkListLarge'));
const LinkListSlim = dynamic(() => import('../LinkListSlim'));
const MarqueeText = dynamic(() => import('../MarqueeText'));
const NewsList = dynamic(() => import('../NewsList'));
const PressMaterial = dynamic(() => import('../PressMaterial'));
const Quote = dynamic(() => import('../Quote'));
const RawHtml = dynamic(() => import('../RawHtml'));
const RichText = dynamic(() => import('../RichText'));
const SavingsCalculator = dynamic(() => import('../SavingsCalculator'));
const TextModule = dynamic(() => import('../TextModule'));
const TitleBlock = dynamic(() => import('../TitleBlock'));
const SideBySide = dynamic(() => import('../SideBySide'));
const UspList = dynamic(() => import('../UspList'));

const StreamField = ({ blocks = [], isErrorPage = false }) => {
    if (!blocks.length) {
        return null;
    }
    const components = {
        ArticleCtaLarge,
        ArticleImageText,
        ArticleNumbers,
        ArticlePreamble,
        ArticleQuote,
        ArticleRichText,
        ChartBlock,
        Contact,
        CtaButton,
        CtaLarge,
        CtaSlim,
        CtaWithImage,
        FaqList,
        FiftyFiftyCtaList,
        HeroArticlePage,
        HeroArticlePageVideo,
        HeroCustomerServicePage,
        HeroLandingPage,
        HeroLandingPageParallax,
        HeroPodcastPage,
        HeroStartPage,
        ImageLinkList,
        InfoText,
        InlineImage,
        LinkListLarge,
        LinkListSlim,
        MarqueeText,
        NewsList,
        PressMaterial,
        Quote,
        RawHtml,
        RichText,
        SavingsCalculator,
        TextModule,
        TitleBlock,
        SideBySide,
        UspList,
    };

    const dynamicComponents = blocks.map((item, index) => {
        const componentName = snakeToPascal(item.type);
        const Component = components[componentName];

        return (
            <Component
                key={item.id}
                {...item.value}
                isErrorPage={isErrorPage}
            />
        );
    });

    return <>{dynamicComponents}</>;
};

StreamField.propTypes = {};

export default StreamField;
